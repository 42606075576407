import React from "react";
import { Router } from "@reach/router";
import { login, logout, isAuthenticated, getProfile } from "../utils/auth";
import { Link } from "gatsby";
import Seo from "../components/seo";

const Home = ({ user }) => {
  return (
    <div className="col-12">
      <p>Hi, {user.name ? user.name : "friend"}!</p>
      <img src={user.picture} alt={user.name} className="rounded-circle" />
    </div>
  );
};

const Settings = () => <p>Settings</p>;

const Billing = ({ user }) => (
  <p>Billing - Stripe ID: {user["https://shop.superbase.com/stripe"]}</p>
);

const Account = () => {
  if (!isAuthenticated()) {
    login();
    return <p>Redirecting to login...</p>;
  }

  const user = getProfile();

  return (
    <>
      <Seo
        title="Home"
        description="Superbase Web Shop Home Page - Buy Superbase NG Products"
      />
      <header className="p-3">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <a
              href="/"
              className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none"
            >
              <img
                src="https://www.superbase.com/wp-content/uploads/2020/10/SuperBase-Alpha.png"
                alt="Superbase Logo"
                className="img-fluid"
                width="120"
                height="70"
              />
            </a>

            <nav className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
              <Link to="/account" className="nav-link px-2 text-dark">
                Account
              </Link>
              <Link to="/account/settings" className="nav-link px-2 text-dark">
                Settings
              </Link>
              <Link to="/account/billing" className="nav-link px-2 text-dark">
                Billing
              </Link>
            </nav>

            <div className="text-end">
              <a
                href="#logout"
                className="nav-link mr-3 text-dark btn btn-warning"
                onClick={(e) => {
                  logout();
                  e.preventDefault();
                }}
              >
                Log Out
              </a>
            </div>
          </div>
        </div>
      </header>

      <main>
        <div className="container">
          <Router>
            <Home path="/account" user={user} />
            <Settings path="/account/settings" />
            <Billing path="/account/billing" user={user} />
          </Router>
        </div>
      </main>

      <footer className="footer mt-auto py-3 bg-dark text-white text-center">
        <p>
          © 2021 Superbase Software Ltd. ·{" "}
          <a href="https://www.superbase.com/privacy/">Privacy</a> ·{" "}
          <a href="https://www.simpol.com/terms-conditions/">Terms</a> ·{" "}
          <a href="https://www.superbase.com/contact/">Contact Us</a>
        </p>
      </footer>
    </>
  );
};

export default Account;
